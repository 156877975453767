import "core-js";
import bootstrap from "bootstrap";
import { createPopper } from '@popperjs/core';
import "../scss/custom.scss";

import "../../portal/scss/main.scss";

function doThing() {
  let tables = document.getElementsByClassName("news_post_table");
  for(let t of tables) {
    let elements = t.getElementsByTagName("table");
    for (let e of elements) {
      let rows = e.getElementsByTagName("tr");
      if (rows.length) {
        for (let tag of rows[0].getElementsByTagName("th")) {
          if (tag.innerText.length === 0) {
            tag.classList.add("d-none");
          }
        }
        rows[0].classList.add("news_post-widget-table-tr-th");
      }
      for (let row of rows) {
        let columns = row.getElementsByTagName("td");
        let content = false;
        for(let col of columns) {
          col.classList.add("news_post-widget-table-tr-td");
          if(col.innerText) {
            content = true
          }
        }
        if(content) {
          row.classList.add("news_post-widget-table-tr");
        } else {
          row.classList.add("news_post-widget-table-spacer");
        }
        let thcolumns = row.getElementsByTagName("th");
        for(let col of thcolumns) {
          col.classList.add("news_post-widget-table-tr-th");
        }
      }

      e.classList.add("news_post-widget-table");
      e.classList.add("responsive");
      e.classList.add("table");
      e.classList.add("justify-content-center");
    }
  }
  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl, {
      dismiss: "hover",
      trigger: "hover",
      delay: { show: 500, hide: 100 },
      html: true,
      sanitize: false,
    });
  });


  var toastElList = [].slice.call(document.querySelectorAll(".toast"));
  var tList = [];
  var toastList = toastElList.map(function (toastEl) {
    var toast = new Toast(toastEl, { delay: 5000 });
    tList.push(toast);
    return toast;
  });
  for (t of tList) {
    t.show();
  }
}

function initialLoadPosts(){
    var queryParams = new URLSearchParams(window.location.search);
    var categories = queryParams.getAll('category');
    for (const category of categories){
        const category_box = document.getElementById(category);
        category_box.checked = true;
    }
    var page = queryParams.get('page')
    if (page === null){
        page = 1;
    }
    loadPosts(page);
}

function reloadPosts(page, filter){
    loadPosts(page);
    if(!filter){
      window.location.hash = '#top';
    }
}

function loadPosts(page){
    var post_container = document.getElementById('news_post_container');
    var pagination_container = document.getElementById('pagination_container');
    var current_items = document.getElementsByClassName('item-block-viz');

    var data = {
        'category': [],
        'locale': json_data['locale'],
        'page': page};
    var category_boxes = document.getElementsByName('category');
    for (let category_box of category_boxes){
        if (category_box.value == 'select_all'){
            continue;
        }

        if (category_box.checked){
            data['category'].push(category_box.value);
        }
    }

    const get_posts = function(){
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'GET',
                url: json_data['posts_api_url'],
                data: data,
                success: function(response){
                    resolve(response);
                    /*
                    request_complete = true;
                    ajax_response = response;
                    update_if_finished();
                    */
                },
                error: function(xhr, text_status, error_thrown){
                    reject(error_thrown);
                    /*
                    request_complete = true;
                    error_thrown = error_thrown;
                    */
                },
                traditional: true
            });
        })
    }

    const add_class_with_transition = function(element, class_to_add){
        return new Promise((resolve, reject) => {
            element.classList.add(class_to_add);
            const transitionEnded = function() {
                element.removeEventListener('transitionend', transitionEnded);
                resolve();
            }
            element.addEventListener('transitionend', transitionEnded);
        });
    }

    Promise.all([
        get_posts(),
        add_class_with_transition(
            post_container,
            'news-post-container-hidden')]).then((results) => {
                var response = results[0];
                post_container.innerHTML = response['posts_html'];
                pagination_container.innerHTML = response['pagination_html'];
                var queryParams = new URLSearchParams(window.location.search);
                queryParams.delete('category');
                for (const value of data['category'].values()){
                    queryParams.append('category', value);
                }
                queryParams.set('locale', data['locale']);
                queryParams.set('page', data['page']);
                history.replaceState(null, null, "?"+queryParams.toString());
                post_container.classList.remove('news-post-container-hidden');
            }).catch((error) => {
                post_container.innerHTML = error;
                pagination_container.innerHTML = '';
                post_container.classList.remove('news-post-container-hidden');
            });
}

function filterActivate(element, event){
    event.stopPropagation();
    if (element.value == 'select_all'){
        var category_boxes = document.getElementsByName('category');
        for (let category_box of category_boxes){
            category_box.checked = element.checked;
        }
    } else {
        var select_box = document.getElementById('select_all');
        if (!element.checked){
            select_box.checked = false;
        }
    }

  reloadPosts(1, true);
}

function tableMerger() {
  let tables = $(".news_post-widget-typedtable table");
  for (let t of tables) {
      let rows = $(t).find('tr');

      for(let r of rows) {

        let target = null;
        let merge_column_count = 0;
        for (let c of $(r).children('td,th')) {
            if (c.innerText.includes("[MERGE]") === true &&
                   target !== null) {
                merge_column_count++;
                c.remove();
            } else {
                if (merge_column_count) {
                    $(target).attr('colspan', merge_column_count + 1);
                    merge_column_count = 0;
                }
                target = c;
            }
        }
        if (merge_column_count) {
            $(target).attr('colspan', merge_column_count + 1);
        }
      }
  }
}


function richTextBlockQuoteClassAdder() {
  let quotes = $(".news_post-widget-richtext blockquote");
  for (let q of quotes) {
    $(q).addClass("blockquote");
  }
}

document.filterActivate = filterActivate;
document.doThing = doThing;
document.loadPosts = loadPosts;
document.reloadPosts = reloadPosts;
document.initialLoadPosts = initialLoadPosts;

document.addEventListener("DOMContentLoaded", richTextBlockQuoteClassAdder, false);
document.addEventListener("DOMContentLoaded", tableMerger, false);
document.addEventListener("DOMContentLoaded", doThing, false);
